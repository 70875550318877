import { AppLocationContext } from "@react-admin/ra-navigation";
import { Layout } from "react-admin";
import {MyMenu} from './MyMenu';
import { MyAppBar } from './MyAppBar';

const MyLayout = (props) =>(
    <AppLocationContext>
        <Layout {...props} menu={MyMenu} appBar={MyAppBar} />
    </AppLocationContext>
)

export default MyLayout;