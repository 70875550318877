import {useEffect, useState} from 'react';
import axios from '../axiosConfig';
const useConstants = () => {
    const [videoStatusArray, setVideoStatusArray] = useState([]);
    const [jobStatusArray, setJobStatusArray] = useState([]);
    const [jobExtraInfoArray, setJobExtraInfoArray] = useState([]);
    const [videoInfoArray, setVideoInfoArray] = useState([]);
    const [channelArray, setChannelArray] = useState([]);
    const [teamArray, setTeamArray] = useState([]);

    useEffect(()=>{
        const fetchData = async () => {
            try{
                const [
                    allVideoStatus, 
                    allVideoInfo, 
                    allJobStatus, 
                    allJobExtraInfo,
                    allTeams, 
                    allChannels,
                    ] = await Promise.all([
                        axios.get(`${process.env.REACT_APP_API_URL}api/token/constants?type=VideoStatus`),
                        axios.get(`${process.env.REACT_APP_API_URL}api/token/constants?type=VideoInfo`),
                        axios.get(`${process.env.REACT_APP_API_URL}api/token/constants?type=JobStatus`),
                        axios.get(`${process.env.REACT_APP_API_URL}api/token/constants?type=JobExtraInfo`),    
                        axios.get(`${process.env.REACT_APP_API_URL}api/team/getTeams`),
                        axios.get(`${process.env.REACT_APP_API_URL}api/channel/getChannels`)
                ]);               
                setVideoStatusArray(allVideoStatus.data.value);
                setVideoInfoArray(allVideoInfo.data.value);
                setJobStatusArray(allJobStatus.data.value);
                setJobExtraInfoArray(allJobExtraInfo.data.value);
                setTeamArray(allTeams.data);
                setChannelArray(allChannels.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    },[]);

    return {
        videoStatusArray, 
        jobStatusArray, 
        jobExtraInfoArray, 
        videoInfoArray,
        channelArray,
        teamArray
    };
}
export default useConstants;