import React, { useState, useContext, useEffect, useRef, useCallback   } from 'react';
import { useRecordContext, useRefresh,useListContext } from 'react-admin';
import { format } from 'date-fns';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from '../axiosConfig';
import { UserContext } from "../auth/UserContext";

const PublishedDateField = ({ source, setLoading }) => {    
    const record = useRecordContext();
    const { selectedIds } = useListContext(); 
    const hasSelection = selectedIds && selectedIds.length > 0;
    const [isEditing, setIsEditing] = useState(false);
    const [publishedDate, setPublishedDate] = useState(record.publishedDate ? format(new Date(record.publishedDate), 'yyyy-MM-dd') : '');
    const refresh = useRefresh();
    const authUser = useContext(UserContext);
    const editRef = useRef(null);
    const canPost = record.team.info.userPosts && record.team.info.userPosts.includes(authUser.id);

    const handleDoubleClick = () => {
        if(hasSelection) return;
        if (canPost) {setIsEditing(true)}
    };

    const handleSave = async () => {
        if (!publishedDate) return;
        const localDate = new Date(publishedDate);
        localDate.setHours(8, 0, 0, 0);
        const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);
        const confirm = window.confirm(`Are you sure you want to set the published date to ${utcDate.toISOString()}?`);
        if (!confirm) return;
        setLoading(true);
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}api/video/${record.id}`, { publishedDate: utcDate.toISOString(), status: 'Posted'});
        } catch (error) {
            console.error(error);
        } finally  {
            refresh();
            setIsEditing(false);
            setLoading(false);
        }
    };

    const handleCancel = useCallback(() => {
        setIsEditing(false);
        setPublishedDate(record.publishedDate ? format(new Date(record.publishedDate), 'yyyy-MM-dd') : '');
    },[record.publishedDate]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (editRef.current && !editRef.current.contains(event.target)) {
                handleCancel();
            }
        }
    
        if (isEditing) {
            document.addEventListener('dblclick', handleClickOutside);
        } else {
            document.removeEventListener('dblclick', handleClickOutside);
        }
        return () => {
            document.removeEventListener('dblclick', handleClickOutside);
        };
    }, [isEditing, handleCancel]);

    if (isEditing) {
        return (
            <div ref={editRef}>
                <input 
                    type="date" 
                    onChange={(e)=>{setPublishedDate(e.target.value)}}
                    value={publishedDate || ''}
                />
                <ButtonGroup>
                    <Button onClick={handleSave}><SaveIcon /></Button>
                    <Button onClick={handleCancel}><CancelIcon /></Button>
                </ButtonGroup>                
            </div>
        );
    }

    return (
        publishedDate?
        <div onDoubleClick={handleDoubleClick} style={{ cursor: 'pointer' }} title={canPost?'Double Click to Change':""}>{publishedDate}</div>
        :
        <div onClick={handleDoubleClick} style={{ cursor: 'pointer' }}>{canPost? 'Click To Set':''}</div>
    );
};

export default PublishedDateField;
