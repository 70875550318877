import React from 'react';
const ImageRenderer = ({jobInfo}) => {
    if(!jobInfo || !jobInfo.idea || jobInfo.idea.length <1) return <img src="/images/no-photo.jpg"  height="72" alt="not found" />;
    return (
        <div>
             <a 
                href={`https://drive.google.com/file/d/${jobInfo.idea[0].id}/view`} 
                target="_blank" rel="noreferrer">
                <img src={`https://drive.google.com/thumbnail?id=${jobInfo.idea[0].id}`} alt="img from GG" height="72" loading="lazy"  />
            </a>
        </div>
    );
};

export default ImageRenderer;