import React, { useState, useContext, useEffect, useCallback   } from 'react';
import { 
    useRecordContext, 
    useRefresh,
    SimpleForm,
    SelectInput,
    ReferenceInput,
    useListContext
 } from 'react-admin';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from '../axiosConfig';
import { UserContext } from "../auth/UserContext";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

const ChannelField = ({ source, setLoading, allChannels }) => {
    const record = useRecordContext();
    const { selectedIds } = useListContext(); 
    const hasSelection = selectedIds && selectedIds.length > 0;
    const [isEditing, setIsEditing] = useState(false);
    const [channel, setChannel] =  useState(record.channelId?record.channelId:null);
    const [channelName, setChannelName] = useState('');
    const refresh = useRefresh();
    const authUser = useContext(UserContext);
    const canPost = record.team.info.userPosts && record.team.info.userPosts.includes(authUser.id);

    useEffect(() => {
        if (record.channelId) {
            const index = allChannels.findIndex(channel => channel.id === record.channelId);
            if(index>=0){setChannelName(allChannels[index].name)}
        }
    },[record.channelId, allChannels]);

    const handleDoubleClick = () => {
        if(hasSelection) return;
        if (canPost) {setIsEditing(true)}
    };

    const handleSaveChannel = async () => {
        setLoading(true);
        try {
            setIsEditing(false);
            await axios.put(`${process.env.REACT_APP_API_URL}api/video/setChannel/${record.id}`, { channelId: channel});            
        } catch (error) {
            console.error(error);
        } finally  {
            refresh();
            setLoading(false);
        }
    };

    const handleCancel = useCallback(() => {
        setIsEditing(false);
        setChannel(record.channelId?record.channelId:null);
    },[record.channelId]);

    return(
        <>
            <Dialog
                open={isEditing}
                onClose={handleCancel}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Select Channel:</DialogTitle>
                <DialogContent >
                    <SimpleForm onSubmit ={handleSaveChannel} toolbar={false} >
                        <ReferenceInput label="Channel" source="channelId" reference="channel" filter={{teamId:record.teamId}} >
                            <SelectInput optionText="name" defaultValue={record.channelId}  onChange={(e) => setChannel(e.target.value)} />
                        </ReferenceInput>
                        <ButtonGroup>
                            <Button type="submit" color="primary" startIcon={<SaveIcon />} >Save</Button>
                            <Button color="secondary" startIcon={<CancelIcon />} onClick={handleCancel}>Cancel</Button>
                        </ButtonGroup>
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            {channelName!==''?
                <div onDoubleClick={handleDoubleClick} style={{ cursor: 'pointer' }} title='Double Click to Change'>
                    {channelName}
                </div>
                :
                <span onClick={handleDoubleClick} style={{ cursor: 'pointer' }}>{canPost?'Click To Set':''}</span>
            }
        </>
    )
};
 export default ChannelField;