import React, {useContext } from "react";
import { Logout, RefreshIconButton, AppBar, UserMenu, useUserMenu  } from 'react-admin';
import { UserContext } from "./auth/UserContext";
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import SettingsIcon from '@mui/icons-material/Settings';

const SettingsMenuItem = React.forwardRef((props, ref) => {
    const { onClose } = useUserMenu();    
    const handleAccountClick = (e) => {
        window.location.href = `/#/user/${props.authUser.id}/show`;
    }
    return (
        <><MenuItem onClick={onClose} ref={ref} {...props}>
            <ListItemText>Hi! {props.authUser.firstName} {props.authUser.lastName}</ListItemText>
        </MenuItem>
        <MenuItem onClick={onClose} ref={ref} {...props}>
            <ListItemIcon>
                <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={(e)=>handleAccountClick(e)}>Manage Your Account</ListItemText>
        </MenuItem></>
    );
});

const MyCustomIcon = (prop) =>{
    return (
        <Avatar
            sx={{
                height: 30,
                width: 30,
            }}
            src={prop.picture?"https://drive.google.com/thumbnail?id="+prop.picture:"https://drive.google.com/thumbnail?id=1YkETmkHHowOjZkyyLA7Xi1QY7zwwOh7o"}
        />
        )
};

const MyUserMenu = (prop) =>{
    return(
        <UserMenu icon={<MyCustomIcon picture={prop.authUser?prop.authUser.picture:null} />}><SettingsMenuItem authUser={prop.authUser} /><Logout/></UserMenu> 
    )};

export const MyAppBar = () => {
    const authUser = useContext(UserContext);
    return (
        <AppBar 
            toolbar={<><RefreshIconButton /></>} 
            color="primary"
            // position='sticky'
            userMenu={<MyUserMenu authUser={authUser}/>}

        />
    )
};