import React, {useEffect, useState} from 'react';
import { Show, 
    SimpleShowLayout, 
    TextField,
    TabbedShowLayout,
    Tab,
    FunctionField,
    useGetRecordId
} from 'react-admin';
import axios from '../axiosConfig';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const ImageRenderer = ({ record }) => {
  if(record.length < 1 || !record[0].id) return <img src="/images/no-photo.jpg"  height="72" alt="not found" />;
  return (
      <div>
          {record.map((item, index) => <a href={`https://drive.google.com/file/d/${item.id}/view`} target="_blank" rel="noreferrer">
              <img 
                src={`https://drive.google.com/thumbnail?id=${item.id}`} 
                alt="img from GG" 
                height="72" 
                loading="lazy" 
                style={{ marginRight: '10px'}} 
              /> 
            </a> )}
      </div>
  );
};

const ShowJob = (props) => {
  const [state, setState] = useState({
    ideaUser: null, 
    outlineUser: null, 
    scriptUser: null,
    editUser: null,
    thumbnailUser: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const recordIdString = useGetRecordId(); 
  const recordId = parseInt(recordIdString, 10); 

  useEffect(() => {

    async function fetchData() {
      setIsLoading(true);
      try{
        const response  = await axios.get(`${process.env.REACT_APP_API_URL}api/task/jobId/${recordId}`);
        const taskData = response.data.tasks;
        const userData = response.data.users;
        const newState = {
          ideaUser : await getUser(taskData, userData,1),
          outlineUser : await getUser(taskData, userData,2),
          scriptUser : await getUser(taskData, userData,4),
          editUser : await getUser(taskData, userData,6),
          thumbnailUser : await getUser(taskData, userData,8)
        }      
        setState(newState);
      }catch(error){
        console.error(error);
      } finally {
        setIsLoading(false);
      }

    }
    fetchData();
  }, [recordId]);  

  function  getUser(taskData, userData, typeId) {
    const task = taskData.filter(task => task.type === typeId);
    if(task.length === 0) return null;
    const userId = task[1]? task[1].staffId:task[0].staffId;
    const user = userData.find(user => user.id === userId);
    return user || null;  
  }

  return(
    <Show {...props}> 
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>     
      <TabbedShowLayout>
        <Tab label="Job Info">
          <SimpleShowLayout>
            <TextField source="name" />
            <FunctionField label="Google Drive Location" render={(record) =>{
                if (!record.directory) return <span>Pending</span>
                return <a rel="noreferrer" target="_blank" href={`https://drive.google.com/drive/folders/${record.directory}`}>{record.directory}</a>}
            }/> 
            <FunctionField label="Idea" render={(record) =>{
              if(!record.jobInfo || !record.jobInfo.idea) return <span>Pending</span>
              return <ImageRenderer record={record.jobInfo.idea}/>
            }}
            />
            <FunctionField label="Outline" render={(record) =>{
              if(!record.jobInfo || !record.jobInfo.outline) return <span>Pending</span>
              return <a href={`https://docs.google.com/document/d/${record.jobInfo.outline}/edit`} target="_blank" rel="noreferrer">View Outline</a>
            }}/>
            <FunctionField label="Script" render={(record) =>{
              if(!record.jobInfo || !record.jobInfo.script) return <span>Pending</span>
              return <a href={`https://docs.google.com/document/d/${record.jobInfo.script}/edit`} target="_blank" rel="noreferrer">View Script</a>
            }}/>
            <TextField source="startAt" />
            <TextField source="filmingDate" />
            <TextField source="status" />
          </SimpleShowLayout>
        </Tab>
        <Tab label="Staff">
          <SimpleShowLayout>
            <FunctionField label="Idea:" render={ () =>state.ideaUser?`${state.ideaUser.firstName} ${state.ideaUser.lastName}`:'Pending'}/>
            <FunctionField label="Outline:" render={ () =>state.outlineUser?`${state.outlineUser.firstName} ${state.outlineUser.lastName}`:'Pending'}/>
            <FunctionField label="Script:" render={ () =>state.scriptUser?`${state.scriptUser.firstName} ${state.scriptUser.lastName}`:'Pending'}/>
            <FunctionField label="Edit:" render={ () =>state.editUser?`${state.editUser.firstName} ${state.editUser.lastName}`:'Pending'}/>
            <FunctionField label="Thumbnail:" render={ () =>state.thumbnailUser?`${state.thumbnailUser.firstName} ${state.thumbnailUser.lastName}`:'Pending'}/>
          </SimpleShowLayout>
        </Tab>
        <Tab label="Video">
          <FunctionField render={(record) =>{
            if(record.type){if (!record.jobInfo || !record.jobInfo.video ) return <span>No Video to Show</span>}
            return (
              <iframe 
                width="1280" height="720" 
                title="View Video"  
                src={`https://drive.google.com/file/d/${record.type? record.jobInfo.video : record.extraInfo.videoId}/preview`}
                frameBorder="0" allowFullScreen>
              </iframe>
            )
          }}/> 
        </Tab>
        <Tab label="Thumbnail">
          <FunctionField render={(record) =>{
            if (!record.jobInfo || !record.jobInfo.thumbnails ) return <span>No Thumbnail to Show</span>
            return (<>
              {record.jobInfo.thumbnails.map((item, index) =>{
                return <img 
                    src={"https://drive.google.com/thumbnail?id="+item.id} 
                    alt="img from GG" height="150" 
                    style={{ border: `2px solid`, cursor: 'pointer' }}
                    loading="lazy" 
                    onClick={() => window.open(`https://drive.google.com/file/d/${item.id}/view`)}
                />
              })}
            </>);
          }}/>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
};

export default ShowJob;