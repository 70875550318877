import axios from 'axios';

// Đặt baseURL cho tất cả yêu cầu
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
}

// Sử dụng interceptors để thiết lập header cho mỗi yêu cầu
axios.interceptors.request.use(config => {
    const auth = getCookie('auth');
    if (auth) {
        config.headers.Authorization = `Bearer ${auth}`;
    }
    return config;
});

export default axios;