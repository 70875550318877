import React , {useState, useContext} from "react";
import { 
    Create, 
    ReferenceInput, 
    required, 
    SimpleForm, 
    TextInput, 
    DateInput, 
    SelectInput,
    useCreate, 
    useNotify, 
    useRedirect
} from "react-admin";
import { isWeekend } from 'date-fns';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { UserContext } from "../auth/UserContext";
const moment = require('moment-timezone');

const addWorkdays = (startDate, days) => {
    let currentDate = moment(startDate);
    while (days > 0) {
        currentDate.add(1, 'days');
        if(currentDate.isoWeekday() !== 6 && currentDate.isoWeekday() !== 7) {
            days--;
        }
    }
    return currentDate;
}

const CreateJob = (props) =>{
    const [isLoading, setIsLoading] = useState(false); 
    const authUser = useContext(UserContext);
    const [team, setTeam] = useState({});
    const [create] = useCreate('job');
    const redirect = useRedirect();
    const notify = useNotify();

    const handleSubmit = async (values) => {     
        let filmDateLocal= moment(values.filmingDate).set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
        if(isWeekend(filmDateLocal) && !window.confirm("FilmingWeekend is not allowed. Do you want to continue?")){return}   

        let startAtLocal = moment(values.startAt).set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
        const data ={
            name: values.name,
            jobTypeId : values.jobTypeId,
            teamId: values.teamId,
            staffId: values.staffId,
            startAt: startAtLocal.utc(),
            filmingDate: filmDateLocal.utc(),
            schedule : values.schedule === 'daily'? 1:values.schedule,
            daily : values.schedule === 'daily'
        }
        setIsLoading(true);
        create('job',{data},{
            onSuccess : (response) => {
                notify('Job Created', 'info', {}, false);
                setIsLoading(false);
                redirect('/job');
            },
            onFailure : (error) => {
                console.error(error); // Xử lý lỗi
            }
        });      
    }

    // create  schedule Choices
    const scheduleChoise = [
        {value : 0, name: "No Schedule"},
        {value : "daily", name: "Daily"},
        {value : 1, name: "Weekdays"},
        {value : 7, name: "Weekly"},
        {value : 14, name: "Biweekly"},
        {value : 28, name: "Monthly"},
    ];

    return (
    <Create {...props}>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>
        <SimpleForm onSubmit={handleSubmit}>
            <TextInput source="name" validate={[required()]} fullWidth label="Job Title"/>
            <ReferenceInput source="jobTypeId" reference="jobType">     
                <SelectInput optionText="name" validate={[required()]}  defaultValue = {1}/>
            </ReferenceInput>
            <ReferenceInput source="teamId" reference="team" filter={{members:{some:{userId:authUser.id, role:3}}}} >
                <SelectInput optionText="name" validate={[required()]} onChange={(e)=>setTeam(e.target.value)}  />
            </ReferenceInput>
            { team>0 && <ReferenceInput source="staffId" reference="user" filter={{teams:{some:{"teamId":team}}, active :true,taskTypes: { some: { taskTypeId: 1 } }}}>
                <SelectInput optionText={(record)=>`${record.firstName} ${record.lastName}`} validate={[required()]} />
            </ReferenceInput>}
            <DateInput source="startAt" validate={[required()]}  defaultValue={new Date()} />
            <DateInput source="filmingDate" validate={[required()]}  defaultValue={addWorkdays(new Date(),4)} />            
            <SelectInput
                label="Schedule Job?"
                source="schedule"
                defaultValue = {7}
                choices={ scheduleChoise }   
                validate={[required()]}      
                optionText="name"
                optionValue="value"     
            />
        </SimpleForm>
    </Create>
)}

export default CreateJob
