import React , {useState} from "react";
import { 
    Create, 
    useCreate,
    SimpleForm,
    ReferenceInput,
    required, 
    TextInput, 
    SelectInput,
    useRedirect
} from "react-admin";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const CreateChannel = (props) =>{
    const [isLoading, setIsLoading] = useState(false); 
    const [create] = useCreate('channel');
    const redirect = useRedirect();

    const handleSubmit = async (values) => {
        setIsLoading(true);
        const data ={
            name: values.name,
            url: values.url,
            teamId: values.teamId
        }
        create('channel',{data},{
            onSuccess : (response) => {
                setIsLoading(false);
                redirect('/channel');
            },
            onFailure : (error) => {
                console.error(error); // Xử lý lỗi
            }
        });    
    }

    return (
    <Create {...props} >
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>
        <SimpleForm onSubmit={handleSubmit}>
            <TextInput source="name" validate={[required()]} />
            <TextInput source="url" validate={[required()]} />
            <ReferenceInput label="Team" source="teamId" reference="team" >
                <SelectInput optionText="name"  validate={[required()]}/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
)}

export default CreateChannel
