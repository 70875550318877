import React, { useState, useEffect } from 'react';
import {
    SelectArrayInput,
    Edit,
    required,
    SimpleForm,
    TextInput,
    SimpleFormIterator,
    ArrayInput,
    useRedirect ,
    useGetRecordId,
    useRefresh
} from "react-admin";
import axios from "../axiosConfig";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const JobTypeEdit= (props) => {
    const recordIdString = useGetRecordId(); 
    const recordId = parseInt(recordIdString, 10);
    const [taskTypes, setTaskTypes] = useState([]);    
    const [isLoading, setIsLoading] = useState(false); 
    const redirect = useRedirect();
    const refresh = useRefresh();

    const removeDuplicates = (arrays) => {
        let seenElements = new Set();
        return arrays.map(array => {
            let filteredArray = array.filter(item => !seenElements.has(item));
            filteredArray.forEach(item => seenElements.add(item));
            return filteredArray;
        });
    }

    const handleSubmit = async (values) => {
        setIsLoading(true);
        values.step = removeDuplicates(values.step)
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}api/jobtype/${recordId}`,  {name:values.name, data:values.data, step:values.step});   
        } catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);
            refresh();
            redirect('/jobtype');
        }
    };

    useEffect(() => {
        const fetchTaskTypes = async () => {
            setIsLoading(true);
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}api/taskType`);
                setTaskTypes(res.data);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTaskTypes();

    }, []);

    return (
        <Edit {...props}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>
            <SimpleForm onSubmit={handleSubmit}>
                <TextInput source="name" validate={[required()]} fullWidth />
                <TextInput label="Day Before Film" source="data.daysBeforeFilm" validate={[required()]} type="number" />
                <ArrayInput label="Job Type Step" source="step" validate={[required()]} fullWidth>
                    <SimpleFormIterator>
                        <SelectArrayInput
                            optionText="name"
                            optionValue="id"
                            choices={taskTypes}
                        />  
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Edit>
    );
}

export default JobTypeEdit;