import React, { useContext, useState } from "react";
import {    
  FunctionField,
  List,
  Datagrid,
  TextField,
  ReferenceField,
  BulkDeleteButton,
  Filter,
  SelectInput,
  TextInput,
  EditButton,
  ReferenceInput, 
  required,
  SimpleForm, 
  DateInput,
  useListContext,
  useRecordContext
} from 'react-admin';
import axios from '../axiosConfig';
import { UserContext } from "../auth/UserContext";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment-timezone';
import { EditDialog } from '@react-admin/ra-form-layout';
import useConstants from "../utils/useConstants";

const CustomBulkActionButtons = ({ authUser }) => (
  <div>
      {authUser.role > 0 && (
          <BulkDeleteButton
              label="Delete Schedules"
              confirmContent="Are you sure you want to delete?"
              confirmTitle="Delete Schedules"   
              mutationMode="optimistic" 
          />
      )}
  </div>
);

const ScheduleFilter = (props) => {
  const {teamArray} = useConstants();
  return (
      <Filter {...props} >
          <TextInput label="Search" source="name" alwaysOn />
          <SelectInput label="Team" source="teamId" choices={teamArray} alwaysOn />
          <ReferenceInput  source="staffId" reference="user" filter={{active:true}} alwaysOn>
              <SelectInput label="Creator" optionText="firstName"  />
          </ReferenceInput> 
      </Filter>
  );
};

const ListSchedule = () => {
  const authUser = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);  
  const [activeSchedule, setActiveSchedule] = useState(null);

  const scheduleChoise = [
    {value : 'daily', name: "Daily"},
    {value : 1, name: "Weekdays"},
    {value : 7, name: "Weekly"},
    {value : 14, name: "Biweekly"},
    {value : 28, name: "Monthly"},
  ];

  const EditField = () => {
    const record = useRecordContext();
    const { selectedIds } = useListContext();
    const hasSelection = selectedIds && selectedIds.length > 0;
    const handleEditClick = () => {
      setActiveSchedule(record);
    };
    if(hasSelection) return null;
    return <EditButton onClick={handleEditClick} /> ;
}

  const handleEditSchedule = async(values) => {
    let nextRunTime = moment(values.nextRunTime).set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
    let currentRunTime = moment(activeSchedule.nextRunTime).set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
    if(nextRunTime.isBefore(currentRunTime)){
      alert('Next Create Job Date must be after the last Create Job Date');
      return;
    }

    let nextFilmDate = moment(values.nextFilmDate).set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
    let currentDate = moment(activeSchedule.nextFilmDate).set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
    if(nextFilmDate.isBefore(currentDate)){
      alert('Next Film Date must be after the last filming date');
      return;
    }
    try{
      setIsLoading(true);
      await axios.put(`${process.env.REACT_APP_API_URL}api/schedule/${values.id}`, {
          title : values.title,
          nextRunTime : nextRunTime.utc().toISOString(),
          nextFilmDate : nextFilmDate.utc().toISOString(),
          scheduleDays : values.scheduleDays === 'daily'? 1:values.scheduleDays,
          daily : values.scheduleDays === "daily"
      });
    } catch (error) {
      console.error(error);
    } finally {
      window.location.replace('/#/schedule');
      setActiveSchedule(null);
      setIsLoading(false);      
    }    
  }

  return (<>
    <List filters={<ScheduleFilter />} sort={{ field: 'id', order: 'DESC' }} >
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>
      <Datagrid bulkActionButtons={<CustomBulkActionButtons authUser={authUser}/>}>            
          <TextField source="title" />
          <ReferenceField source="staffId" reference="User" label="User">
              <FunctionField render={(record) => `${record.firstName} ${record.lastName}`}  />
          </ReferenceField>
          <FunctionField label="Cycle" render={(record) => `${record.scheduleDays} Days`} />
          <FunctionField label="Next Film Date" render={(record) =>{
              const utcTime = record.nextFilmDate;
              const localTime = moment.utc(utcTime).toDate();
              return localTime.toLocaleString();
          }} />
          <FunctionField label="Next Create Job Date" render={(record) =>{
              const utcTime = record.nextRunTime;
              const localTime = moment.utc(utcTime).toDate();
              return localTime.toLocaleString();
          }} />
          <EditField  label="Edit"/>
      </Datagrid>
    </List>
    <EditDialog title ="Edit Schedule">
      <SimpleForm onSubmit={handleEditSchedule}>
        <TextInput source="title" validate={[required()]} defaultValue={activeSchedule?activeSchedule.title:''} />
        <ReferenceInput source="jobTypeId" reference="jobType">     
            <SelectInput optionText="name" validate={[required()]}  defaultValue = {activeSchedule?activeSchedule.jobTypeId:''} disabled/>
        </ReferenceInput>
        <ReferenceInput source="teamId" reference="team" filter={{id:activeSchedule? activeSchedule.teamId:1}} >
            <SelectInput optionText="name" disabled defaultValue={activeSchedule?activeSchedule.teamId:1} />
        </ReferenceInput>
        <ReferenceInput source="staffId" reference="user" filter={{id:activeSchedule? activeSchedule.staffId :1}}>
          <SelectInput 
            optionText={(record)=>`${record.firstName} ${record.lastName}`} 
            defaultValue={activeSchedule?activeSchedule.staffId:1}
            disabled 
          />
        </ReferenceInput>
        <DateInput source="nextRunTime" validate={[required()]}  defaultValue={activeSchedule? new Date(activeSchedule.nextRunTime) : ''}  label='Next Create Job Date' />
        <DateInput source="nextFilmDate" validate={[required()]}  defaultValue={activeSchedule? new Date(activeSchedule.nextFilmDate) : ''} label="Next Film Date" />            
        <SelectInput
            label="Reapeat"
            source="scheduleDays"
            defaultValue = {activeSchedule?activeSchedule.daily? "daily" : activeSchedule.scheduleDays:7}
            choices={ scheduleChoise }   
            validate={[required()]}      
            optionText="name"
            optionValue="value"     
        />
      </SimpleForm>
    </EditDialog>
    </>
  );
};

export default ListSchedule;