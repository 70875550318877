import React , {useState} from "react";
import { 
    Edit, 
    useUpdate,
    SimpleForm,
    ReferenceInput,
    required, 
    TextInput, 
    SelectInput,
    useNotify, 
    useRedirect
} from "react-admin";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const EditChannel = (props) =>{
    const [isLoading, setIsLoading] = useState(false); 
    const [update] = useUpdate('channel');

    const redirect = useRedirect();
    const notify = useNotify();

    const handleSubmit = async (values) => {
        setIsLoading(true);
        const data ={
            name: values.name,
            url : values.url,
            teamId: values.teamId
        }
        update('channel',{id:values.id,data:data},{
            onSuccess : (response) => {
                notify('Channel Updated', 'info', {}, false);
                setIsLoading(false);
                redirect('/channel');
            },
            onFailure : (error) => {
                console.error(error); // Xử lý lỗi
            }
        });    
    }

    return (
    <Edit {...props} mutationMode="optimistic">
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>
        <SimpleForm onSubmit={handleSubmit}>
            <TextInput source="name" validate={[required()]} />
            <TextInput source="url" validate={[required()]} />
            <ReferenceInput label="Team" source="teamId" reference="team" >
                <SelectInput optionText="name" validate={[required()]} />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
)}

export default EditChannel
